import React, { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';

const CodeEditor = ({ language, onChange }) => {
    const [code, setCode] = useState('');

    const handleEditorDidMount = (editor, monaco) => {
        if (monaco && language) {
            monaco.editor.setModelLanguage(editor.getModel(), language);
        }
    };

    // Default code snippets for different languages
    const defaultCodes = {
        python: '// Python code here\nprint("Hello, Python!")',
        java: '// Java code here\npublic class Main {\n\tpublic static void main(String[] args) {\n\t\tSystem.out.println("Hello, Java!");\n\t}\n}',
        javascript: '// JavaScript code here\nconsole.log("Hello, JavaScript!");',
        c: '// C code here\n#include <stdio.h>\n\nint main() {\n\tprintf("Hello, C!");\n\treturn 0;\n}',
        cpp: '// C++ code here\n#include <iostream>\n\nint main() {\n\tstd::cout << "Hello, C++!";\n\treturn 0;\n}',
    };

    useEffect(() => {
        setCode(defaultCodes[language]);
    }, [language]);

    const handleCodeChange = (newCode) => {
        setCode(newCode);
        // Pass the code back to the parent component via onChange prop
        onChange(newCode);
    };

    return (
        <Editor
            height="85vh"
            defaultLanguage={language}
            value={code} 
            onMount={handleEditorDidMount}
            onChange={handleCodeChange} 
            options={{
                minimap: {
                    enabled: false,
                },
                theme: 'vs-light', 
                fontSize: 16, 
            }}
            wrapperClassName="editor-wrapper"
            className="code-editor"
        />
    );
};

export default CodeEditor;
