import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ZoneOutContainer from '../ClarityEd/Swe/ZoneOut/ZoneOutContainer/ZoneOutContainer';
import ContentAndTopQuestionsContainer from '../ClarityEd/Swe/ZoneOut/ContentAndTopQuestionsContainer/ContentAndTopQuestionsContainer';

function ZoneOutsRoutes() {
    return (
        <Routes>
            <Route path='/' element={<ContentAndTopQuestionsContainer />} />
            <Route path="/ZoneOutContainer" element={<ZoneOutContainer />} />
        </Routes>
    );
}

export default ZoneOutsRoutes;
