import React, { useState } from 'react';
import './Intuition.css';

const Intuitions = () => {
  // Use a set to track open indices
  const [openIndices, setOpenIndices] = useState([]);

  const intuitions = [
    {
      intuition: "Prompt for input.",
      actions: [
        "Use a Scanner to read input from the console.",
        "Prompt the user with a clear message."
      ]
    },
    {
      intuition: "Validate numeric input.",
      actions: [
        "Check input validity with try-catch blocks.",
        "Provide feedback on invalid input."
      ]
    },
    {
      intuition: "Add two numbers.",
      actions: [
        "Convert input to integers.",
        "Perform the addition operation."
      ]
    },
    {
      intuition: "Display the result.",
      actions: [
        "Print the sum to the console.",
        "Format the output message clearly."
      ]
    },
    {
      intuition: "Handle invalid input.",
      actions: [
        "Show an error message.",
        "Prompt for valid numbers again."
      ]
    }
  ];

  const handleIntuitionClick = (index) => {
    setOpenIndices(prevOpenIndices => {
      if (prevOpenIndices.includes(index)) {
        return prevOpenIndices.filter(i => i !== index);
      } else {
        return [...prevOpenIndices, index];
      }
    });
  };

  return (
    <div>
      <p className='IntuitionHeading'>
        Intuitions you need to have to solve this problem.
      </p>
      {intuitions.map((value, index) => (
        <div key={index} style={{ marginBottom: "20px" }}>
          <p
            className='Intuition'
            style={{ cursor: 'pointer' }}
            onClick={() => handleIntuitionClick(index)}
          >
            {value.intuition}
          </p>
          {openIndices.includes(index) && (
            <div>
              {value.actions.map((val, actionIndex) => (
                <p
                  className='AcitionText'
                  key={actionIndex}
                >
                  {val}
                </p>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Intuitions;
