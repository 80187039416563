import React, { useState } from 'react';
import './Header.css';
import { Logo } from '../../images';
import { Link } from 'react-router-dom';

const Header = ({ MenuOptions, ClickedOption, selectedMenuOption, HeaderFor }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="sticky-header">
      <div className="header-container">
        <Link to="/" className="logo-container" onClick={() => { if (isMenuOpen) setIsMenuOpen(false); }}>
          <img src={Logo} alt="Logo" className="logo-img" />
          <p className="brand-name">ClarityEd</p>
          {HeaderFor === 'SWE' && (
            <p
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                color: '#148bec',
                border: '2px solid #148bec',
                borderRadius: '3px',
                paddingInline: '3px',
              }}
            >
              SWE
            </p>
          )}
        </Link>

        {/* Hamburger icon for mobile */}
        <div className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>

        <nav className={`navigation ${isMenuOpen ? 'active' : ''}`} >
          <ul className="nav-links">
            {MenuOptions.map((option, index) => (
              <li key={index}>
                <Link
                  to={HeaderFor === 'SWE' ? `/swe${option === 'Home' ? '' : `/${option}`}` : option === 'SWE' && "/swe"}
                  className="Link"
                  style={{
                    color: selectedMenuOption === option ? '#148bec' : 'white',
                  }}
                  onClick={() => {
                    if (HeaderFor !== 'SWE') {
                      if (option !== 'SWE') {
                        ClickedOption(option);
                      }
                    }
                    if (isMenuOpen) {
                      setIsMenuOpen(false);
                    }
                  }}
                >
                  {option}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
