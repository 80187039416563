import React, { useEffect, useState } from 'react';
import './TopQuestions.css';

export default function TopQuestions({ showButton, visited }) {
    const questionsAndAnswers = [
        {
            question: 'What is an array in C++?',
            answer: 'An array is a collection of elements of the same type stored in contiguous memory locations.',
        },
        {
            question: 'How do you declare an array in C++?',
            answer: 'You can declare an array using the syntax: type arrayName[arraySize];',
        },
        {
            question: 'How do you access elements in an array?',
            answer: 'You access elements in an array using zero-based indexing, e.g., array[0], array[1], etc.',
        },
        {
            question: 'Can the size of an array be changed after its declaration?',
            answer: 'No, the size of an array is fixed at the time of its declaration and cannot be changed later.',
        },
        {
            question: 'What is the syntax to initialize an array during declaration?',
            answer: 'The syntax to initialize an array during declaration is: type arrayName[arraySize] = {value1, value2, ...};',
        },
        {
            question: 'What happens if you access an array out of its bounds?',
            answer: 'Accessing an array out of its bounds results in undefined behavior.',
        },
        {
            question: 'How is the size of an array calculated?',
            answer: 'The size of an array can be calculated using sizeof(array) / sizeof(array[0]).',
        },
        {
            question: 'What are the advantages of using arrays?',
            answer: 'Arrays provide efficient access to elements and allow storing multiple elements of the same type in contiguous memory locations.',
        },
        {
            question: 'What are the limitations of arrays?',
            answer: 'Arrays have fixed size, lack bounds checking, and cannot be resized after declaration.',
        },
        {
            question: 'How do you initialize an array with all elements set to zero?',
            answer: 'You can initialize an array with all elements set to zero using the syntax: type arrayName[arraySize] = {0};',
        },
    ];

    const [showAnswers, setShowAnswers] = useState(
        Array(questionsAndAnswers.length).fill(visited ? true : false)
    );

    // New useEffect to check if all questions have been visited
    useEffect(() => {
        // Check if all answers are true (i.e., all questions have been visited)
        const allVisited = showAnswers.every(answered => answered);
        if (allVisited) {
            showButton(true);
        }
    }, [showAnswers, showButton]);

    const handleQuestionClick = (index) => {
        setShowAnswers((prev) => {
            const newShowAnswers = [...prev];
            if (!newShowAnswers[index]) {
                newShowAnswers[index] = true;
            }
            return newShowAnswers;
        });
    };

    return (
        <div className="container" id="TopQuestions" style={{ display: 'block', paddingInline: '100px' }}>
            <p className="mb-5" style={{ color: '#388ae9', fontSize: "25px", fontWeight: 'bold' }}>
                Top Questions
            </p>
            <div id="qa-container">
                {questionsAndAnswers.map((qa, index) => (
                    <div key={index} className="QuestionContainer">
                        <div
                            className="question"
                            onClick={() => handleQuestionClick(index)}
                            style={{ backgroundColor: showAnswers[index] ? "#d9ffda" : '' }}
                        >
                            <span className="QuestionText">{qa.question}</span>
                            <span className="icon" style={{ fontSize: '15px' }}>{showAnswers[index] ? '✔' : '▼'}</span>
                        </div>
                        <div
                            className="answer"
                            style={{ display: showAnswers[index] ? 'block' : 'none', textAlign: 'left' }}
                        >
                            {qa.answer}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
