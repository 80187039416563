import React from 'react';

const Dropdown = ({ options, selectedOption, onSelect }) => {
    return (
        <select
            style={{ width: '100px', padding: '2px', borderRadius: '4px', border: '1px solid grey', backgroundColor: '#f1f1f1', color: 'black' }}
            value={selectedOption} onChange={(e) => onSelect(e.target.value)}>
            {options.map((option) => (
                <option key={option.value} value={option.value} >
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default Dropdown;
