import React, { useState } from 'react';
import './Assistant.css';

const Assistant = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');

    const handleSendMessage = () => {
        if (input.trim()) {
            setMessages([...messages, { text: input, sender: 'user' }]);
            setInput('');
            // Simulate bot response
            setTimeout(() => {
                setMessages((prevMessages) => [...prevMessages, { text: 'This is Assistant response.', sender: 'bot' }]);
            }, 1000);
        }
    };

    return (
        <div className="chat-container">
            <div className="chat-box">
                {messages.map((message, index) => (
                    <div key={index} className={`chat-message ${message.sender}`}>
                        {message.text}
                    </div>
                ))}
            </div>
            <div className="chat-input">
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                    placeholder="Type a message..."
                />
                <div className='SendButton' style={input === '' ? { cursor: 'default' } : {}} onClick={handleSendMessage}>Send</div>
            </div>
        </div>
    );
};

export default Assistant;
