import React, { useState } from 'react'
import './TestCases.css'

export default function TestCases({ testCases, ChangeCaseChange }) {

    const [selectedTestCase, setSelectedTestCase] = useState(0);
    const [inputValue, setInputValue] = useState(testCases[0].input);

    const handleChange = (event) => {
        setInputValue(event.target.value);
        ChangeCaseChange(event.target.value);
    };

    const TestCaseButton = ({ index, data }) => {
        return (
            <div className='TestCase' style={{ backgroundColor: selectedTestCase === index ? '#dedede' : ' white' }}
                onClick={() => {
                    setInputValue(data.input);
                    setSelectedTestCase(index);
                }
                }
            >
                <p className='TestCaseText' >Case{index + 1}</p>
            </div>
        )
    }

    return (
        <div style={{ paddingInline: '10px', paddingBlock: '10px' }}>
            <div style={{ display: 'flex' }}>
                {
                    testCases.slice(0, 2).map((data, index) => {
                        return (
                            <TestCaseButton key={index} index={index} data={data} />
                        )
                    })
                }
            </div>
            <div className="input-container">
                <label htmlFor="numsInput" className="input-label">nums = </label>
                <input
                    type="text"
                    id="numsInput"
                    value={inputValue}
                    onChange={handleChange}
                    className="styled-input"
                />
            </div>
        </div>
    )
}
