import React, { useEffect, useState } from 'react'
import './CodeOutput.css'

export default function CodeOutput({ output, isError, showOutput, OutputFor, isLoading }) {

    const [PassedTestCases, setPassedTestCases] = useState(0);
    const [selectedTestCase, setSelectedTestCase] = useState(0);

    useEffect(() => {
        if (!isError) {
            let count = 0;
            output?.map((item) => {
                if (item.success) {
                    count++;
                }
            })
            setPassedTestCases(count);
        }
    })

    const  TestCaseButton = ({ index, data }) => {
        return (
            <div className='TestCase' style={{ backgroundColor: selectedTestCase === index ? '#dedede' : ' white', borderColor: data.success ? 'green' : 'red' }}
                onClick={() => {
                    setSelectedTestCase(index);
                }
                }
            >
                <p className='TestCaseText' style={{ color: data.success ? 'green' : 'red' }}>Case{index + 1}</p>
            </div>
        )
    }

    return (
        <>
            {
                !isLoading &&
                (
                    <div className='outputContainer'>
                        {
                            isError ? (
                                <p style={{ color: 'red' }}>{output}</p>
                            ) :
                                (
                                    showOutput && (
                                        <div style={{ width: '100%' }}>
                                            <div className='d-flex justify-content-between'>
                                                {
                                                    OutputFor === 'run' ? (
                                                        <p style={{ fontWeight: 'bold', color: output[selectedTestCase].success ? 'green' : 'red' }}>{`${output[selectedTestCase].success ? 'Accepted' : 'Failed'}`}</p>
                                                    ) : (
                                                        <p style={{ fontWeight: 'bold', color: PassedTestCases === output?.length ? 'green' : 'red' }}>{`${PassedTestCases === output.length ? 'Accepted' : 'Failed'}`}</p>
                                                    )
                                                }
                                                <p>Passed test cases : {`${PassedTestCases} / ${output?.length}`}</p>
                                            </div>
                                            {
                                                OutputFor === 'run' && (
                                                    <div style={{ display: 'flex' }}>
                                                        {
                                                            output.map((data, index) => {
                                                                return (
                                                                    < TestCaseButton index={index} data={data} />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            }
                                            {
                                                OutputFor === 'run' ?
                                                    (
                                                        <div style={{ padding: '30px' }}>
                                                            <div style={{ borderRadius: '5px', backgroundColor: '#f1f1f1', padding: '10px', marginBottom: '20px' }}>
                                                                <p className='m-0' style={{ fontWeight: 'bold' }}>Input :</p>
                                                                <p>{output[selectedTestCase].input}</p>
                                                                <p className='m-0' style={{ fontWeight: 'bold' }}>Your Output :</p>
                                                                <p>{output[selectedTestCase].actualOutput}</p>
                                                                <p className='m-0' style={{ fontWeight: 'bold' }}>Expected Output :</p>
                                                                <p>{output[selectedTestCase].expectedOutput}</p>
                                                            </div>
                                                        </div>
                                                    ) :
                                                    (
                                                        <div style={{ paddingBottom: '30px' }}>
                                                            {
                                                                output?.map((item, index) => {
                                                                    if (!item.success) {
                                                                        return (
                                                                            <div key={index} style={{ borderRadius: '5px', backgroundColor: '#f1f1f1', padding: '10px', marginBottom: '20px' }}>
                                                                                <p className='m-0' style={{ fontWeight: 'bold' }}>Input :</p>
                                                                                <p>{item.input}</p>
                                                                                <p className='m-0' style={{ fontWeight: 'bold' }}>Your Output :</p>
                                                                                <p>{item.actualOutput}</p>
                                                                                <p className='m-0' style={{ fontWeight: 'bold' }}>Expected Output :</p>
                                                                                <p>{item.expectedOutput}</p>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    )
                                )
                        }
                    </div>
                )
            }
        </>

    )
}
