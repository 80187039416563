import React, { useEffect, useState } from 'react';
import "./SweHome.css";
import Header from '../../../Components/Header/Header';
import { RoadMap, SoftwareDeveloper } from '../../../images';

export default function SweHome() {
  const MenuOptions = ["Home", "ZoneOuts", "Profile"];
  const [selectedMenuOption, setSelectedMenuOption] = useState("Home");
  const [showLogin, setShowLogin] = useState(true);

  const handleMenuOptionClick = (option) => {
    setSelectedMenuOption(option);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  //this is stop the scroll in the swe Home.
  useEffect(() => {
    if (showLogin) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [showLogin]);

  const handleCloseModal = () => setShowLogin(false);

  
  const handleLoginSubmit = () => {
    console.log('Login Done');
    handleCloseModal();
  };


  return (
    <div>
      <Header MenuOptions={MenuOptions} ClickedOption={handleMenuOptionClick} HeaderFor={"SWE"} selectedMenuOption={selectedMenuOption} />
      {
        showLogin && (
          <div className="modal-overlay" >
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              {/* <GoogleOAuthProvider clientId="389455233869-psapuhb1bcq87gf7qf80oh2rcka800pa.apps.googleusercontent.com">
                <Login  handleCloseModal={handleLoginSubmit} />
              </GoogleOAuthProvider> */}

              <p className='mb-md-3' style={{ color: '#388ae9', fontWeight: 'bold', fontSize: '25px' }}>Coming Soon ...</p>
              <p style={{fontSize:'20px'}}><span style={{fontWeight:'bold'}}>SWE</span> Under development</p>
              
            </div>
          </div>
        )
      }

      {/* first container for Swe Motive*/}
      <div className="container text-center " style={{ paddingTop: '100px' }}>
        <h5>Powerful, easier and faster</h5>
        <p className='Heading'>
          A New Way To Learn
          <br />
          <span className="Blue_cl">Coding</span> Skills
        </p>
      </div>


      {/* second container about SWE */}
      <div className="container my-5 py-4 px-3 px-lg-5" style={{ paddingTop: '100px' }}>
        <div className="row px-1">
          <div className="col-md-6 d-flex flex-column  p-0 px-1">
            <h3 className="Blue_cl mb-5" style={{ fontWeight: 'bold', textAlign: 'left' }}>What exactly is this?</h3>
            <img className='d-block d-md-none mb-3' src={SoftwareDeveloper} width="100%" style={{ borderRadius: '10px' }} />
            <div className="pl-2">
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}>To bring our subjective thoughts into objective reality
                <span style={{ fontWeight: 'bold' }}> using software</span>.
                <br />
                <br />
                Whether your core goal is to build an amazing software <span style={{ fontWeight: 'bold' }}>Start
                  Up</span> from starch or to crack major mnc
                <span style={{ fontWeight: 'bold' }}> SWE Job</span> our program helps you to be
                <span style={{ fontWeight: 'bold' }} className="Green_cl"> PRO</span> in using software part.
              </p>
            </div>
          </div>
          <div className="col-md-6  text-center">
            <img className='d-none d-md-block' src={SoftwareDeveloper} width="450px" style={{ borderRadius: '10px' }} />
          </div>
        </div>
      </div>


      {/* third container for RoadMaps */}
      <div className="container my-5 py-5 px-3 px-lg-5" style={{ paddingTop: '100px' }}>
        <div className="row ">
          <div className="col-md-6 text-center">
            <img className='d-none d-md-block' src={RoadMap} width="450px" />
          </div>
          <div className="col-md-6 d-flex flex-column   ">
            <h3 className="Blue_cl mb-5" style={{ fontWeight: 'bold', textAlign: 'left' }}>Learning Steps</h3>
            <img className='d-block d-md-none mb-4' src={RoadMap} width="100%" />
            <div className="pl-3">
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>1.</span> Learn a
                language (python or java).</p>
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>2.</span> Learn Data
                Structure & ALgorithms (DSA).</p>
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>3.</span> Uderstand
                system designs</p>
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Result :</span> crack
                faang (if u need money first 😉) or start a software company (if u already have people nd money
                😎)</p>
            </div>
          </div>
        </div>
      </div>


      {/* fourth container for pricing*/}

      <div id="Pricing" className="container my-5 py-5 px-3 px-lg-5" style={{ paddingTop: '50px' }}>
        <h3 className="Blue_cl mb-5" style={{ fontWeight: 'bold', textAlign: 'left' }}>What will be Pricing?</h3>
        <div className="row d-flex flex-row justify-content-around  px-md-5 px-3">
          <div className="card p-3 mb-4" style={{ width: '22rem', borderRadius: '10px', border: '2px solid #c1c1c1 ' }}>
            <div className="card-body d-flex flex-column">
              <h3 style={{ fontWeight: 'bold', color: '#313336', textAlign: 'left' }}>Go Pro</h3>
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }} >~ 3 ZoneOuts (each 2hrs).</p>
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}>~ Ai Assistent.</p>
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}>~ Pro Meter.</p>
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}>~ Slower Results.</p>
            </div>
            <a href="#" className="btn mx-2" style={{ color: 'white', backgroundColor: '#313336', paddingBlock: '3px' }}>₹
              1,999</a>
          </div>
          <div className="card p-3 mb-4" style={{ width: '22rem', borderRadius: '10px', border: '2px solid #c1c1c1 ' }}>
            <div className="card-body d-flex flex-column">
              <h3 className="Blue_cl" style={{ fontWeight: 'bold', textAlign: 'left' }}>Go Pro <span style={{ fontSize: '17px' }}>With
                Mentor</span></h3>
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}>~ 3 ZoneOuts (each 2hrs).</p>
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}>~ Ai Assistent.</p>
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}>~ Pro Meter.</p>
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}>~ Faster Results.</p>
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}>~ Mentor.</p>
            </div>
            <a href="#" className="btn mx-2" style={{ color: 'white', backgroundColor: '#388ae9', paddingBlock: '3px' }}>₹
              2,999</a>
          </div>
        </div>
      </div>


      {/* fifth container how it works? */}

      <div className="container my-5 py-5 px-3 px-lg-5" style={{ paddingTop: '50px' }}>
        <h3 className="Blue_cl" style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '50px' }}>How it Works ?</h3>
        <div>
          <div className="col d-flex flex-column   ">
            <h4 style={{ fontWeight: 'bold', textAlign: 'left' }}>Program</h4>
            <div className="pl-3">

              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}>Very simple you subscribe to program. Depending on your
                standard you start from <span style={{ fontWeight: 'bold' }}>1, 2,</span> or
                directly <span style={{ fontWeight: 'bold' }}>3 step</span>.
                <br />
                <br />
                Do <span style={{ fontWeight: 'bold' }}>1 or 2 zoneout sessions</span> per day
                (depending on ur
                program plan).
                <br />
                <br />
                you have <span style={{ fontWeight: 'bold' }}>pro meter</span> to calculate the time
                it takes to
                complete the step or program.
              </p>

            </div>
          </div>
          <div className="col d-flex flex-column mt-5" >
            <h4 style={{ fontWeight: 'bold', textAlign: 'left' }}>Zoneouts</h4>
            <div className="pl-3">
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}>we make the content and questions you pratice curated in
                such a way that it is <span style={{ fontWeight: 'bold' }}>not too hard or not too
                  easy</span> for you — a perfect recipe for <span style={{ fontWeight: 'bold' }}>pure focus or
                    flow
                    state</span>.
                <br />
                <br />
                you have an <span style={{ fontWeight: 'bold' }}>ai assistant</span> always with you
                to help u
                progress the zoneout session if u r blocked
                <br />
                <br />
                we make sure u will completely zoneout during the session and get high once it is done (<span
                  style={{ fontWeight: 'bold' }}>sustainable dopamine</span> 😊)
              </p>

            </div>
          </div>
        </div>
      </div>



      {/* sixth container */}
      <div className="container my-5 py-5 px-3 px-lg-5" style={{ paddingTop: '50px' }}>
        <div className="row ">
          <div className="col-md-12 d-flex flex-column   ">
            <h3 style={{ fontWeight: 'bold', color: 'red', textAlign: 'left' }}>Rules</h3>
            <br />
            <div className="pl-3">
              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}>We are here to not pamper you but to bring out <span
                style={{ fontWeight: 'bold' }}>hero</span> that is hiding inside you.
                To bring best version from your no so best version we have to be <span
                  style={{ fontWeight: 'bold' }}>strict</span> & <span style={{ fontWeight: 'bold' }}>not cool</span>
                when it comes to these rules.
                <br />
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  ** You will be <span style={{ fontWeight: 'bold', color: 'red' }}>out</span> of program with full
                  refund, if you are not following any of these below rules.**
                </span>
              </p>

              <p className="lead" style={{ fontWeight: '400', textAlign: 'left' }}>At the end of this journey we change you become <span
                style={{ fontWeight: 'bold' }}>pro
                software engineer & amazing person</span> to work with in general (a true <span
                  class="Blue_cl" style={{ fontWeight: 'bold' }}>HERO</span>). The path to is so hard but not if u
                follow the
                below rules.
                <div>
                  <br />
                  <h5 style={{ fontWeight: 'bold' }}>1. No Multitasking in zoneouts</h5>
                  <div className="pl-4">
                    <p className="lead" style={{ fontWeight: '400' }}>
                      When you are zone out you are inaccessible to the outside world, your
                      entire focus should be on the content of your session.
                      <br />
                      we make sure the zoneout session is fully engaging, not too hard or not too easy for
                      you.
                    </p>
                  </div>
                  <h5 style={{ fontWeight: 'bold' }}>2. By Finishing Session</h5>
                  <div className="pl-4">
                    <p className="lead" style={{ fontWeight: '400' }}>
                      Once u start ur sesison u have to finish it (unless its regarding health or some
                      emergency)
                    </p>
                  </div>
                  <h5 style={{ fontWeight: 'bold' }}>2. Not Missing ZontOuts</h5>
                  <div className="pl-4">
                    <p className="lead" style={{ fontWeight: '400' }}>
                      Missing half of the zoneouts (5 or 10 depending on your plan) in last 10 days
                    </p>
                  </div>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>


      {/* footer here */}
      <footer style={{ backgroundColor: '#313336' }} class="text-white p-4 text-center">
        &copy; 2024 ClarityEd. All Rights Reserved.
      </footer>

    </div >
  );
}
