import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './QuestionDescription.css';

// const content = `
// **Description**

// ## 1. Two Sum

// **Easy**

// Given an array of integers \`nums\` and an integer \`target\`, return **indices of the two numbers such that they add up to** \`target\`.

// You may assume that each input would have exactly one solution, and you may not use the same element twice.

// You can return the answer in any order.

// ### Example 1:

// \`\`\`plaintext
// Input: nums = [2,7,11,15], target = 9
// Output: [0,1]
// Explanation: Because nums[0] + nums[1] == 9, we return [0, 1].
// \`\`\`

// ### Example 2:

// \`\`\`plaintext
// Input: nums = [3,2,4], target = 6
// Output: [1,2]
// Explanation: Because nums[1] + nums[2] == 6, we return [1, 2].
// \`\`\`

// ### Example 3:

// \`\`\`plaintext
// Input: nums = [3,3], target = 6
// Output: [0,1]
// \`\`\`

// ### Constraints:

// - 2 ≤ \`nums.length\` ≤ 10
// - -10 ≤ \`nums[i]\` ≤ 10
// - -10 ≤ \`target\` ≤ 10

// Only one valid answer exists.
// `;



const inputText = `Description

1. Two Sum


Given an array of integers nums and an integer target, return indices of the two numbers such that they add up to target.

You may assume that each input would have exactly one solution, and you may not use the same element twice.

You can return the answer in any order.

Example 1:

Input:  nums = [2,7,11,15], target = 9 
Output: [0,1] 
Explanation: Because nums[0] + nums[1] == 9, we return [0, 1].

Example 2:

Input:  nums = [3,2,4], target = 6 
Output: [1,2] 
Explanation: Because nums[1] + nums[2] == 6, we return [1, 2].


TExt 

#include <iostream>


int main() {
	std::cout << "Hello, C++!";
	return 0;
}

Constraints:

2 ≤ nums.length ≤ 10
-10 ≤ nums[i] ≤ 10
-10 ≤ target ≤ 10

Only one valid answer exists.
`;



const transformText = (input) => {
    const lines = input.split('\n').filter(line => line.trim() !== '');

    let result = '';
    let inExample = false;

    lines.forEach((line, index) => {
        const trimmedLine = line.trim();

        // Handle Description Header
        if (index === 0) {
            result += `**${trimmedLine}**\n\n`;
        }
        // Handle Main Heading
        else if (index === 1) {
            result += `## ${trimmedLine}\n\n`;
        }

        // Handle Examples
        else if (trimmedLine.startsWith('Example')) {
            if (inExample) result += '```\n\n'; // Close previous code block
            result += `### ${trimmedLine}:\n\n\`\`\`plaintext\n`;
            inExample = true;
        }
        // Handle Constraints
        else if (trimmedLine.startsWith('Constraints:')) {
            if (inExample) result += '```\n\n'; // Close example block
            result += `### ${trimmedLine}\n\n`;
            inExample = false;
        }
        // Handle Input/Output/Explanation Lines
        else if (trimmedLine.startsWith('Input:') || trimmedLine.startsWith('Output:') || trimmedLine.startsWith('Explanation:')) {
            result += `${trimmedLine}\n`;
        }
        // Handle Constraints List
        else if (/^\d+\s≤\snums.length\s≤\s\d+/.test(trimmedLine) || /^-?\d+\s≤\snums\[i\]\s≤\s-?\d+/.test(trimmedLine) || /^-?\d+\s≤\starget\s≤\s-?\d+/.test(trimmedLine)) {
            result += `- \`${trimmedLine}\`\n`;
        }
        // Handle Regular Paragraphs
        else {
            result += `${trimmedLine}\n\n`;
        }
    });

    // Close any remaining open code block
    if (inExample) result += '```\n\n';

    return result;
};

const QuestionDescription = () => {
    return (
        <div className="description-container">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{transformText(inputText)}</ReactMarkdown>
        </div>
    );
}

export default QuestionDescription;