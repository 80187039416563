import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ZoneOutsRoutes from './ZoneOutsRoutes';
import SweHome from '../ClarityEd/Swe/SweHome/SweHome';
import Profile from '../ClarityEd/Swe/Profile/Profile';

function SweRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SweHome />} />
      <Route path="/ZoneOuts/*" element={<ZoneOutsRoutes />} />
      <Route path="/Profile" element={<Profile />} />
    </Routes>
  );
}

export default SweRoutes;
