import React, { useEffect, useState } from 'react';
import SplitPane from 'react-split-pane';
import './ZoneOutContainer.css';
import axios from 'axios';
import QuestionDescription from './ZoneOutContainerComponents/QuestionDescription/QuestionDescription';
import Assistant from './ZoneOutContainerComponents/Assistant/Assistant';
import Intuitions from './ZoneOutContainerComponents/Intuition/Intuitions';
import Dropdown from './ZoneOutContainerComponents/DropDown/Dropdown';
import CodeEditor from './ZoneOutContainerComponents/CodeEditor/CodeEditor';
import Submission from './ZoneOutContainerComponents/Submission/Submission';
import TestCases from './ZoneOutContainerComponents/TestCases/TestCases';
import CodeOutput from './ZoneOutContainerComponents/CodeOutput/CodeOutput';
import Spinner from '../../../../Components/Spinner/Spinner';




const ZoneOutContainer = ({navigate}) => {
    const [selectedOption, setSelectedOption] = useState('Question');
    const [selectedLanguage, setSelectedLanguage] = useState('java');
    const [code, setCode] = useState('');
    const [selectedConsole, setSelectedConsole] = useState('TestCases');
    const [showOutput, setShowOutput] = useState(false);
    const [output, setOutput] = useState(null);
    const [isError, setIsError] = useState(false);
    const [showCodeRunInterface, setShowCodeRunInterface] = useState(false);
    const [selectedLanguageVersion, setSelectedLanguageVersion] = useState("0.0.0");
    const [OutputFor, setOutputFor] = useState('run');
    const [isLoading, setIsLoading] = useState(false);


    const languageOptions = [
        { label: 'JavaScript', value: 'javascript' },
        { label: 'Python', value: 'python' },
        { label: 'Java', value: 'java' },
        { label: 'C++', value: 'cpp' },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    //this call is to know which version of laguage is the api is using.
    useEffect(() => {
        const getLanguageVersion = async () => {
            try {
                const response = await axios.get('https://emkc.org/api/v2/piston/runtimes');
                response.data.map((data) => {
                    if (data.language === selectedLanguage) {
                        setSelectedLanguageVersion(data.version);
                        return
                    }
                })
            } catch (error) {
                console.error('Error fetching Java versions:', error);
            }
        };
        getLanguageVersion();
    }, [selectedLanguage])


    const testCases = [
        { input: "2 3\n", expectedOutput: "5\n" },
        { input: "4 7\n", expectedOutput: "11\n" },
        { input: "10 20\n", expectedOutput: "30\n" },
        { input: "11 20\n", expectedOutput: "31\n" },
        { input: "20 20\n", expectedOutput: "40\n" },
        { input: "2 3\n", expectedOutput: "5\n" },
        { input: "4 7\n", expectedOutput: "11\n" },
        { input: "10 20\n", expectedOutput: "30\n" },
        { input: "11 20\n", expectedOutput: "31\n" },
        { input: "20 20\n", expectedOutput: "40\n" },
    ];


    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
    };

    const handleCodeChange = (newCode) => {
        setCode(newCode);
    };


    const clickRunCode = async () => {
        setIsLoading(true);
        setOutputFor('run');
        clickRunTestCode(testCases.slice(0, 2));
    };

    const onClickSubmit = async () => {
        setIsLoading(true);
        setOutputFor('submit');
        clickRunTestCode(testCases);
    }


    const clickRunTestCode = async (TestCases) => {

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        const results = [];

        for (const testCase of TestCases) {
            try {
                const response = await axios.post('https://emkc.org/api/v2/piston/execute', {
                    language: selectedLanguage,
                    version: selectedLanguageVersion,
                    files: [{ name: 'main.cpp', content: code }],
                    stdin: testCase.input
                });

                //this is to handle if the given code is wrong
                if (response.data.run.stdout === '' || response.data.run.stdout === null) {
                    setIsError(true);
                    setOutput(response.data.run.output);
                    setShowOutput(true);
                    setSelectedConsole("Output");
                    setShowCodeRunInterface(true);
                    setIsLoading(false);
                    return
                }
                else {
                    results.push({
                        input: testCase.input,
                        expectedOutput: testCase.expectedOutput,
                        actualOutput: response.data.run.stdout,
                        success: response.data.run.stdout.trim() === testCase.expectedOutput.trim()
                    });
                }

            } catch (error) {
                if (error.response) {
                    console.error('Error response:', error.response.data);
                    if (error.response.status === 409) {
                        console.error('Conflict:', error.response.data);
                    }
                } else if (error.request) {
                    console.error('Error request:', error.request);
                } else {
                    console.error('Error message:', error.message);
                }
                results.push({ error: error.message });
            }

            await delay(1);
        }

        let newOption = 'Submission';

        for (const item of results) {
            if (!item.success) {
                newOption = 'Assistant';
                if (OutputFor === 'Submit') {
                    break; 
                }
            }
        }
        setSelectedOption(newOption);



        setSelectedConsole("Output");
        setShowCodeRunInterface(true);
        setIsError(false);
        setShowOutput(true);
        setOutput(results);
        setIsLoading(false);
    };


    return (
        <div style={{ height: '100vh' }}>
            <div className='Header' >
                <div
                    className='HeaderButton'
                    onClick={() => {
                        navigate(-1); // Go back to the previous page
                    }}
                >
                    <p className='m-0'>Content</p>
                </div>

            </div >
            <div className='ZoneOutContainer'>
                <SplitPane split="vertical" minSize={50} defaultSize="50%" >
                    <div className='ZoneOutLeftContainer'>
                        <div className='topContainer OptionsContainer' >
                            <div className={`${selectedOption === 'Question' && 'SelectedOption'}`} onClick={() => setSelectedOption('Question')} style={{
                                cursor: 'pointer',
                                paddingInline: '10px',
                                marginRight: "10px",
                            }}>
                                <p style={selectedOption === 'Question' ? { fontWeight: 'bold' } : {}}>Question</p>
                            </div>

                            <div className={`${selectedOption === 'Assistant' && 'SelectedOption'}`} onClick={() => setSelectedOption('Assistant')} style={{
                                cursor: 'pointer',
                                paddingInline: '10px',
                                marginRight: "10px",
                            }}>
                                <p style={selectedOption === 'Assistant' ? { fontWeight: 'bold', color: "#388ae9" } : { color: "#388ae9" }}>Assistant</p>
                            </div>

                            <div className={`${selectedOption === 'Intuitions' && 'SelectedOption'}`} onClick={() => setSelectedOption('Intuitions')} style={{
                                cursor: 'pointer',
                                paddingInline: '10px',
                            }}>
                                <p style={selectedOption === 'Intuitions' ? { fontWeight: 'bold', color: "#028e00" } : { color: "#028e00" }}>Intuitions</p>
                            </div>


                            <div className={`${selectedOption === 'Submission' && 'SelectedOption'}`} onClick={() => setSelectedOption('Submission')} style={{
                                cursor: 'pointer',
                                paddingInline: '10px',
                            }}>
                                <p style={selectedOption === 'Submission' ? { fontWeight: 'bold' } : {}}>Submission</p>
                            </div>
                        </div>

                        <div className='bottomContainer' style={{ backgroundColor: 'white', height: '100%', overflowY: 'auto', padding: '15px' }}>
                            {
                                selectedOption === 'Question' && <QuestionDescription />
                            }
                            {
                                selectedOption === 'Assistant' && <Assistant />
                            }
                            {
                                selectedOption === 'Intuitions' && <Intuitions />
                            }
                            {
                                selectedOption === 'Submission' && <Submission />
                            }
                        </div>

                    </div>

                    <div className='ZoneOutRightContainer'>
                        <SplitPane split="horizontal" minSize={50} defaultSize={showCodeRunInterface ? '60%' : '100%'} size={showCodeRunInterface ? '60%' : '100%'} className="SplitPane" style={{ position: 'unset' }}>
                            <div>
                                <div className='topContainer'>
                                    <Dropdown
                                        options={languageOptions}
                                        selectedOption={selectedLanguage}
                                        onSelect={handleLanguageChange}
                                    />
                                </div>
                                <CodeEditor language={selectedLanguage} onChange={handleCodeChange} />
                            </div>

                            <div style={{ backgroundColor: "white", display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <div className='topContainer' style={{
                                    backgroundColor: '#ececec',
                                    paddingTop: '5px',
                                }}>
                                    <div className={`${selectedConsole === 'TestCases' && 'SelectedOption'}`} onClick={() => setSelectedConsole('TestCases')} style={{
                                        cursor: 'pointer',
                                        paddingInline: '10px',
                                        marginRight: '10px',
                                    }}>
                                        <p style={selectedConsole === 'TestCases' ? { fontWeight: 'bold' } : {}}>TestCases</p>
                                    </div>

                                    <div className={`${selectedConsole === 'Output' && 'SelectedOption'}`} onClick={() => setSelectedConsole('Output')} style={{
                                        cursor: 'pointer',
                                        paddingInline: '10px',
                                    }}>
                                        <p style={selectedConsole === 'Output' ? { fontWeight: 'bold' } : {}}>Output</p>
                                    </div>
                                </div>
                                {
                                    selectedConsole === 'Output' ? (
                                        (
                                            <CodeOutput output={output} isError={isError} showOutput={showOutput} OutputFor={OutputFor} isLoading={isLoading} />
                                        )
                                    ) :
                                        (
                                            <TestCases testCases={testCases} />
                                        )
                                }
                            </div>
                        </SplitPane>
                        <div className='BottomBarStyle' >
                            <div className='Button' onClick={() => setShowCodeRunInterface((prev) => !prev)}>
                                {
                                    showCodeRunInterface ? <span style={{ color: 'gray' }}> &#9660;</span> : <span style={{ color: 'gray' }}>&#9650;</span>
                                }
                            </div>
                            {
                                isLoading ? (
                                    <Spinner />
                                ) :
                                    (
                                        <div style={{ display: 'flex' }}>
                                            <div className='Button'
                                                style={{
                                                    paddingBlock: '3px',
                                                    color: 'black',
                                                    marginRight: '3px',
                                                }}
                                                onClick={() => clickRunCode()}
                                            >
                                                Run
                                            </div>

                                            <div className='Button'
                                                style={{
                                                    paddingBlock: '3px',
                                                    color: 'white',
                                                    border: '2px solid #028e00',
                                                    backgroundColor: '#028e00',
                                                }}
                                                onClick={() => {
                                                    onClickSubmit();
                                                }
                                                }
                                            >
                                                Submit
                                            </div>
                                        </div>
                                    )
                            }

                        </div>
                    </div>
                </SplitPane>
            </div>
        </div>
    );
};

export default ZoneOutContainer;
