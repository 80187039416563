import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Content from './ContentAndTopQuestionsComponents/Content/Content';
import TopQuestions from './ContentAndTopQuestionsComponents/TopQuestions/TopQuestions';

let visited = false;
export default function ContentAndTopQuestionsContainer() {

  const [show, setShow] = useState('Both');

  const [showPracticeQuestionButton, setShowPracticeQuestionButton] = useState(visited ? true : false);


  const showButton = (value) => {
    setShowPracticeQuestionButton(value);
  };

  return (
    <>
      <div className='d-none d-md-block'>
        {
          show === 'Content' && <Content />
        }
        {
          show === "Both" && (
            <div>
              <Content />
              <TopQuestions showButton={showButton} visited={visited} />
            </div>
          )
        }
        {
          show === "TopQuestions" && <TopQuestions showButton={showButton} />
        }

        <div className="container">
          <h3 className="text-center">{visited ? 'Now Continue your ZoneOut' : showPracticeQuestionButton ? "Now Move on to Practice Questions" : "Visit all Top Questions to enable button"}</h3>
          <div className="text-center my-4">
            <Link
              to={show === "Content" ? "../ZoneOutContainer" : (showPracticeQuestionButton ? "../ZoneOutContainer" : '')}
              className="Link px-5 py-1 rounded-lg"
              style={{
                textDecoration: "none",
                transform: show === "Content" ? '' : (!showPracticeQuestionButton && 'none'),
                color: "white",
                backgroundColor: show === "Content" ? "#388ae9" : (showPracticeQuestionButton ? "#388ae9" : "grey"),
                padding: 0,
                margin: 0,
                cursor: show === "Content" ? '' : (showPracticeQuestionButton ? "pointer" : "default"),
              }}
              onClick={() => visited = true}
            >
              {visited ?'Continue ZoneOut': 'Practice Questions'}
            </Link>
          </div>
        </div>
      </div>
      <div className='d-flex d-md-none justify-center align-items-center pb-5' style={{ height: "100vh" }}>
        <h1 className='text-center' style={{ color: '#388ae9' }} >
          please visit this page in Desktop
        </h1>
      </div>
    </>
  );
}
