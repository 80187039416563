import React from 'react';
import './Content.css'; // Assuming you have a CSS file for styling

export default function Content() {
    return (
        <>
            <div className="container-fluid">
                <div className="content-container">
                    <h2 className="Blue_cl">Content</h2>
                    <br />
                    <div>
                        <h2>Understanding Arrays in C++</h2>

                        <h4>Definition and Declaration</h4>
                        <p>
                            Arrays in C++ are declared using a fixed size that must be known at compile time. The syntax for declaring
                            an array is:
                        </p>
                        <div className="code">
                            <code>type arrayName[arraySize];</code>
                        </div>
                        <p>Where:</p>
                        <ul>
                            <li>
                                <strong>type</strong>: specifies the type of elements the array will hold (e.g., <code>int</code>,{' '}
                                <code>double</code>, <code>char</code>).
                            </li>
                            <li>
                                <strong>arrayName</strong>: is the identifier or name of the array.
                            </li>
                            <li>
                                <strong>arraySize</strong>: specifies the number of elements the array can hold. It must be a constant
                                expression.
                            </li>
                        </ul>

                        <h4>Accessing Elements</h4>
                        <p>
                            Elements in an array are accessed using zero-based indexing. For example, to access the first element of an
                            array <code>arr</code>, you use <code>arr[0]</code>, and to access the second element, you use{' '}
                            <code>arr[1]</code>, and so on.
                        </p>

                        <h4>Initialization</h4>
                        <p>Arrays can be initialized during declaration or later using assignment. Here are examples of initialization:</p>
                        <div className="code">
                            <pre>
                                <code>
                                    {`// Declaration with initialization
int arr1[5] = {1, 2, 3, 4, 5};

// Declaration without initialization (elements are uninitialized)
int arr2[3];

// Assigning values later
arr2[0] = 10;
arr2[1] = 20;
arr2[2] = 30;`}
                                </code>
                            </pre>
                        </div>

                        <h4>Characteristics</h4>
                        <ul>
                            <li>
                                <strong>Fixed Size:</strong> Once declared, the size of the array cannot be changed.
                            </li>
                            <li>
                                <strong>Contiguous Memory:</strong> Elements are stored sequentially in memory, which allows for
                                efficient access but requires contiguous space.
                            </li>
                            <li>
                                <strong>Performance:</strong> Array access is O(1) time complexity, meaning accessing an element is fast
                                and direct.
                            </li>
                        </ul>

                        <h4>Usage</h4>
                        <p>Arrays are commonly used for:</p>
                        <ul>
                            <li>Storing and accessing a collection of data elements of the same type.</li>
                            <li>
                                Implementing algorithms that require sequential access to elements, such as searching, sorting, and
                                traversing.
                            </li>
                        </ul>

                        <h4>Example</h4>
                        <p>Here’s a simple example illustrating the use of an array in C++:</p>
                        <div className="code">
                            <pre>
                                <code>
                                    {`#include <iostream>

int main() {
    // Declaring and initializing an array of integers
    int arr[5] = {10, 20, 30, 40, 50};

    // Accessing and printing elements of the array
    std::cout << "Elements of the array:" << std::endl;
    for (int i = 0; i < 5; ++i) {
        std::cout << "arr[" << i << "] = " << arr[i] << std::endl;
    }

    return 0;
}`}
                                </code>
                            </pre>
                        </div>

                        <div className="code">
                            <p>
                                You are given a 2D integer array <code>intervals</code>, where{' '}
                                <code>intervals[i] = [left<sub>i</sub>, right<sub>i</sub>]</code> describes the{' '}
                                <code>i<sup>th</sup></code> interval starting at <code>left<sub>i</sub></code> and ending at{' '}
                                <code>right<sub>i</sub></code> <strong>(inclusive)</strong>. The <strong>size</strong> of an
                                interval is defined as the number of integers it contains, or more formally{' '}
                                <code>right<sub>i</sub> - left<sub>i</sub> + 1</code>.
                            </p>

                            <p>
                                You are also given an integer array <code>queries</code>. The answer to the{' '}
                                <code>j<sup>th</sup></code> query is the <strong>size of the smallest interval</strong>{' '}
                                <code>i</code> such that{' '}
                                <code>
                                    left<sub>i</sub> &lt;= queries[j] &lt;= right<sub>i</sub>
                                </code>
                                . If no such interval exists, the answer is <code>-1</code>.
                            </p>

                            <p>
                                Return <em>an array containing the answers to the queries</em>.
                            </p>

                            <p>
                                <strong className="example">Example 1:</strong>
                            </p>
                            <pre>
                                <strong>Input:</strong> intervals = [[1,4],[2,4],[3,6],[4,4]], queries = [2,3,4,5]
                                <strong>Output:</strong> [3, 3, 1, 4]
                                <strong>Explanation:</strong> The queries are processed as follows:
                                - Query = 2: The interval [2,4] is the smallest interval containing 2. The answer is 4 - 2 + 1 = 3.
                                - Query = 3: The interval [2,4] is the smallest interval containing 3. The answer is 4 - 2 + 1 = 3.
                                - Query = 4: The interval [4,4] is the smallest interval containing 4. The answer is 4 - 4 + 1 = 1.
                                - Query = 5: The interval [3,6] is the smallest interval containing 5. The answer is 6 - 3 + 1 = 4.
                            </pre>

                            <p>
                                <strong className="example">Example 2:</strong>
                            </p>
                            <pre>
                                <strong>Input:</strong> intervals = [[2,3],[2,5],[1,8],[20,25]], queries = [2,19,5,22]
                                <strong>Output:</strong> [2,-1,4,6]
                                <strong>Explanation:</strong> The queries are processed as follows:
                                - Query = 2: The interval [2,3] is the smallest interval containing 2. The answer is 3 - 2 + 1 = 2.
                                - Query = 19: None of the intervals contain 19. The answer is -1.
                                - Query = 5: The interval [2,5] is the smallest interval containing 5. The answer is 5 - 2 + 1 = 4.
                                - Query = 22: The interval [20,25] is the smallest interval containing 22. The answer is 25 - 20 + 1 = 6.
                            </pre>

                            <p>
                                <strong>Constraints:</strong>
                            </p>
                            <ul>
                                <li>
                                    <code>1 &lt;= intervals.length &lt;= 10^5</code>
                                </li>
                                <li>
                                    <code>1 &lt;= queries.length &lt;= 10^5</code>
                                </li>
                                <li>
                                    <code>intervals[i].length == 2</code>
                                </li>
                                <li>
                                    <code>1 &lt;= left<sub>i</sub> &lt;= right<sub>i</sub> &lt;= 10^7</code>
                                </li>
                                <li>
                                    <code>1 &lt;= queries[j] &lt;= 10^7</code>
                                </li>
                            </ul>

                        </div>


                        <h4>Important Notes</h4>
                        <ul>
                            <li>
                                <strong>Bounds Checking:</strong> C++ does not perform bounds checking on array accesses. Accessing an
                                index outside the declared size results in undefined behavior.
                            </li>
                            <li>
                                <strong>Size Calculation:</strong> The size of an array can be determined using{' '}
                                <code>sizeof(arr) / sizeof(arr[0])</code>, where <code>arr</code> is the array name.
                            </li>
                        </ul>

                        <p>
                            Arrays in C++ provide a foundational way to work with collections of data efficiently, especially when the
                            size of the collection is fixed and known at compile time.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
