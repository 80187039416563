import React, { useEffect, useState } from 'react'
import './ClarityEd.css'
import { Brain, LetterA, MindSet, Thinking } from '../images'
import Header from '../Components/Header/Header'
import { useNavigate } from 'react-router-dom'

export default function ClarityEd() {

  const navigate = useNavigate();
  const [selectedVision, setSelectedVision] = useState('we bring out excellence from every student');
  const [selectedMenuOption, setSelectedMenuOption] = useState("Home");
  const MenuOptions = ["Home", "Amigos", "SWE", "About us"];


  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleMenuOptionClick = (option) => {
    setSelectedMenuOption(option);
    if (option === "SWE") {
      navigate("/swe");
    }
    else {
      scrollToSection(option);
    }
  };


  const handleScroll = () => {
    const sections = ["Home", "Amigos", "SWE", "About us"];
    const scrollPosition = window.scrollY + window.innerHeight / 2;

    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element) {
        const { offsetTop, offsetHeight } = element;
        if (
          scrollPosition >= offsetTop &&
          scrollPosition < offsetTop + offsetHeight
        ) {
          setSelectedMenuOption(section);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const VisionItems = {
    "we bring out excellence from every student": {
      "heading": 'Pro',
      'ContentHtml': `Our commitment lies in employing the latest technologies to deeply understand each student's unique learning style, strengths, and areas for growth.<br><br> By doing so, we aim to transform the educational experience into a more personalized and effective journey. Our tools are designed not just to educate, but to inspire and bring to the surface the innate <span class='BlueText' >excellence</span> within every learner.<br><br> Our tools are more than just for learning; they're made to discover and draw out the <span class='BlueText' >hidden talents</span> in every student. Join us on this journey to make learning personal and effective for everyone. With clarityED, every student gets a chance to show their <span class='BlueText' >best</span>.`,
    },
    'Heroic excellence in action': {

      "heading": 'Heroic Experience',
      'ContentHtml': `We believe that the true goal of learning goes far beyond the mere accumulation of information. Education, in its most impactful form, is about nurturing 'heroic excellence in action.'<br><br> It’s about empowering students not just to know, but to act; not just to understand, but to make a difference. Our focus extends beyond the traditional confines of learning.<br><br> While knowledge is the foundation, we emphasize its application – turning <span class='BlueText' >theory into practice</span>, and <span class='BlueText' >lessons into leadership</span>. We inspire our students to be proactive, to use their learning as a tool for <span class='BlueText' >real-world impact</span>.`,
    },
    'Research Oriented learning approach': {

      "heading": 'Neuroscience Techniques',
      'ContentHtml': `We follow a research-oriented approach to learning, blending advanced educational strategies with insights from neuroscience. Our meticulously designed tools aim to maintain a consistent level of <span class='BlueText' >dopamine</span>, the key neurotransmitter linked to pleasure and <span class='BlueText' >motivation</span>, in students. This ensures a positive and receptive state of mind, enhancing the overall learning experience.<br><br>Recognizing the vital role of mood and motivation, our educational tools are crafted to keep students consistently engaged, creating a productive, <span class='BlueText' >enjoyable</span>, and stress-free learning environment. Inspired by social media techniques, our system intelligently leverages dopamine peaks and troughs to reinforce daily practice, motivating students to interact regularly with the material in a rewarding manner.<br><br>Our ultimate goal is to instill a habit of daily practice and deep learning. Aligning with the natural rhythms of student motivation and happiness, our tools make the learning process effective and appealing, enhancing academic performance and contributing to a positive and balanced educational experience.`,
    },
    'Nurturing Minds, Shaping Characters': {

      "heading": 'Green Alphas',
      'ContentHtml': `we are driven by a profound understanding that the essence of education extends beyond mere academic intelligence.<br><br> We firmly believe that the true purpose of education is to foster both <span class='BlueText' >intellectual growth</span> and <span class='BlueText' >character development</span>.<br><br> This dual focus is at the core of everything we do.  Our innovative tools and resources are designed to challenge and engage students, promoting a deeper understanding and mastery of subjects and also focus on shaping up their characters.`
    }
  }

  return (
    <div>
      <Header MenuOptions={MenuOptions} ClickedOption={handleMenuOptionClick} selectedMenuOption={selectedMenuOption} />
      {/* this is for ClarityEd Motive */}
      <div id='Home' className="container-fluid d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <h1 className='MainText' >
          Reinforcing <span style={{ color: '#388ae9' }}>education</span>
          <br />
          using cutting edge technology.
        </h1>
      </div>

      {/* this is Student Amigos container */}
      <div id="Amigos">
        <div className='AmigoContainer'>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: "10px" }}>
            <div>
              <img src={LetterA} alt="Logo" height="80" width="80" />
              <p className="outline">Amigo <sub className="Subscript">for School</sub></p>
            </div>
            <p className='AmigoText' >
              Designed to help both students and teacher in their journey for success.
            </p>
            <p className='AmigoText' >
              Provides tools to assist teachers in planning, content generation, and tracking student performance.
            </p>
            <p className='AmigoText'>Students are the primary focus of this tool. It makes learning a more enjoyable activity, improves skills and standards faster with calm and clarity, and enhances their emotional maturity to handle their intelligence effectively.</p>
            <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '25px', color: '#000000', marginTop: '20px', width: '100%' }}>Stages</p>
            <div className='graph-Container' >
              <div className="graph-Icon-Text-Container">
                <div className='Web-graph-image'>
                  <img src={Brain} alt="Logo" height="50" style={{ marginBottom: '5px' }} />
                  <div className="barGraph" style={{ height: '130px' }}>

                  </div>
                </div>
                <div style={{ paddingTop: '30px' }}>
                  <div className='graph-Text-Mobile'>
                    <img className='Image' src={Brain} alt="Logo" height="50" style={{ marginBottom: '5px' }} />
                    <p className='graph-text'>3 - 6 Months</p>
                  </div>
                  <ul className="ListStyle">
                    <li>More interest in targeted subjects.</li>
                    <li>Increase in focus</li>
                    <li>Clear analysis on performance</li>
                  </ul>
                </div>
              </div>

              <div className="graph-Icon-Text-Container">
                <div className='Web-graph-image'>
                  <img src={MindSet} alt="Logo" height="50" style={{ marginBottom: '5px' }} />
                  <div class="barGraph" style={{ height: '240px' }}>

                  </div>
                </div>
                <div style={{ paddingTop: '30px' }}>
                  <div className='graph-Text-Mobile'>
                    <img className='Image' src={MindSet} alt="Logo" height="60" style={{ marginBottom: '5px' }} />
                    <p className='graph-text'>6 - 12 Months</p>
                  </div>
                  <ul className="ListStyle">
                    <li>Improve in student standards</li>
                    <li>Clear analysis on his strength / comfort / weak areas in subject</li>
                    <li>Clear distance from main goal</li>
                  </ul>
                </div>
              </div>


              <div className="graph-Icon-Text-Container">
                <div className='Web-graph-image'>
                  <img src={Thinking} alt="Logo" height="50" style={{ marginBottom: '5px' }} />
                  <div class="barGraph" style={{ height: '320px' }}>

                  </div>
                </div>
                <div style={{ paddingTop: '30px' }}>
                  <div className='graph-Text-Mobile'>
                    <img className='Image' src={Thinking} alt="Logo" height="50" style={{ marginBottom: '5px' }} />
                    <p className='graph-text'>12 - 24 Months</p>
                  </div>
                  <ul className="ListStyle">
                    <li>Clear determination on main goal</li>
                    <li>Ready for more intense preparation</li>
                    <li>More maturity to handle his intelligence in a good way to
                      make
                      positive impact on the society</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div style={{ borderBottom: '2px solid gray', width: '100%', marginTop: '60px', marginBottom: '20px', marginInline: 'auto' }}>

          </div>

          {/* for college */}
          <div className="Collage">
            <div>
              <img src={LetterA} alt="Logo" height="80" width="80" />
              <p className="outline">Amigo <sub className="Subscript">for Collage</sub></p>
            </div>

            <p className='AmigoText' >
              Designed to help college students achieve their big dreams in an easier, more reliable, and calmer way. It improves their skills and standards as quickly as possible while maintaining clarity all along.
            </p>
            <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '25px', color: '#000000', marginTop: '20px', width: '100%' }}>Primary Advantages</p>

            <div className="PointsContainer d-flex flex-column ">
              <div className='ListItem'>
                Personalized learning progress
              </div>
              <div className='ListItem'>
                Clear tracking of acquired skills
              </div>
              <div className='ListItem'>
                Clear Visualization on strengths and weakness
              </div>
              <div className='ListItem'>
                Zone outs
              </div>
              <div className='ListItem'>
                Perfect estimation for timeline to reach final goal
              </div>
            </div>

            <p className=' text-md-center mx-2' style={{ fontWeight: '400', letterSpacing: '1px' }}>For now, we focus on helping computer science college students become pro <a href='/swe' style={{ fontWeight: 'bold' }}>software engineers</a> and crack big tech interviews that pay <span style={{ fontWeight: 'bold' }}>similar or more than</span> those for IIT graduates. In the future, we will expand to support other fields as well.</p>
          </div>
        </div>
      </div>

     

      {/* this is About Us container */}

      <div id='About us'>
        <h1 className='HeadingText'>What we actually do?</h1>

        {/* this is for web UI */}
        <div className='vision-wrapper-web' >
          <div class="vision-left-container" >
            <div className='vision-Heading'>
              <h4><strong>{VisionItems[selectedVision].heading}</strong></h4>
            </div>
            <p className='vision-content'
              dangerouslySetInnerHTML={{ __html: VisionItems[selectedVision].ContentHtml }}
            >
            </p>
          </div>

          <div className="Vision-Content-container">
            {
              Object.keys(VisionItems).map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`vision-item ${selectedVision === item ? 'highlight' : 'dimmed'}`}
                    onClick={() => setSelectedVision(item)}
                  >
                    <p>{item}</p>
                  </div>
                )
              })
            }
          </div>
        </div>


        {/* this is for Mobile UI */}
        <div className='vision-wrapper-mobile'>
          <div className="Vision-Content-container">
            {
              Object.keys(VisionItems).map((item, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className={`vision-item ${selectedVision === item ? 'highlight' : 'dimmed'}`}
                      onClick={() => setSelectedVision(item)}
                    >
                      <p>{item}</p>
                    </div>
                    {
                      selectedVision === item && (
                        <div class="vision-left-container" >
                          <div className='vision-Heading'>
                            <h4><strong>{VisionItems[item].heading}</strong></h4>
                          </div>
                          <p className='vision-content'
                            dangerouslySetInnerHTML={{ __html: VisionItems[item].ContentHtml }}
                          >
                          </p>
                        </div>
                      )
                    }
                  </>
                )
              })
            }
          </div>
        </div>
      </div>



      {/* this is fifth container for footer */}

      <div className='footer'>
        <div className="footer-content">
          <div className="contact-info" >
            <p className='footer-heading' style={{ color: '#148bec' }}>Contact us</p>
            <p>Rajiv Talluri</p>
            <p>Phone: +1 (717) 999-3280</p>
            <p>Email: <span id="email">Rajiv.talluri@clarityed.pro</span></p>
          </div>


          <div className="Footer-Links">
            <p className='footer-heading'>Links</p>
            <ul>
              <li onClick={() => handleMenuOptionClick("Home")}>Home</li>
              <li onClick={() => handleMenuOptionClick("Amigos")}>Amigos</li>
              <li onClick={() => handleMenuOptionClick("SWE")} >SWE</li>
              <li onClick={() => handleMenuOptionClick("About us")}>About us</li>
            </ul>
          </div>

          <div class="About-info" >
            <p className='footer-heading'>About us</p>
            <p style={{ color: 'white' }}>Reinforcing education using our cutting edge technology.</p>
          </div>
        </div>
        <div class="legal-info">
          <p style={{ color: 'white', textAlign: 'center' }}>&copy; copyright 2024 , All rights reserved by <span style={{ fontWeight: 'bold' }}>clarityED</span> </p>
        </div>
      </div>

    </div>
  )
}
