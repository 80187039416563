import React from 'react';

import './Submission.css';

let submissions = [
    { date: '20-07-2024', question: 'Two Sum', status: '' },
];

const Submission = () => {

    const getStatusColor = (status) => {
        if (status === 'Accepted') {
            return '#028e00';
        }
    }

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th className="date-submitted">Submission Date</th>
                        <th>Question</th>
                        <th className="status">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {submissions.map((submission, index) => (
                        <tr key={index}>
                            <td className="date-submitted" >{submission.date}</td>
                            <td>
                                <p style={{ margin: 0 }}>{submission.question}</p>
                            </td>
                            <td className="status" style={{ color: getStatusColor(submission.status), fontWeight: 'bold' }}>{submission.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>


            {/* this is the problem solving for a Question need to fix later*/}
            <div>
                <p style={{ color: 'grey', marginBottom: "15px", fontSize: '21px', fontWeight: 'bold' }}>Problem Solving</p>
                <p style={{ marginTop: 0, marginLeft: "10px", fontSize: "15px" }}>Use a Scanner object to read input from the console.
                    Prompt the user with clear messages to enter the first and second numbers.</p>
                <p style={{ marginTop: 0, marginLeft: "10px", fontSize: "15px" }}>Check if the input is a valid number using try-catch blocks.
                    If the input is invalid, provide feedback by displaying an error message.</p>
                <p style={{ marginTop: 0, marginLeft: "10px", fontSize: "15px" }}>Convert the input strings to integers.
                    Perform the addition operation.</p>
                <p style={{ marginTop: 0, marginLeft: "10px", fontSize: "15px" }}>Print the sum to the console.
                    Format the output message clearly to inform the user of the result.</p>
                <p style={{ marginTop: 0, marginLeft: "10px", fontSize: "15px" }}>If the input is invalid, show an error message.
                    Prompt the user to enter valid numbers again, repeating the process until valid input is provided.</p>
            </div>

            {
                false && (
                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                        <div style={{
                            cursor: 'pointer',
                            paddingInline: '20px',
                            paddingBlock: '3px',
                            color: 'white',
                            border: '2px solid #0077ff',
                            borderRadius: '4px',
                            marginRight: '3px',
                            backgroundColor: '#0077ff',
                            fontWeight: '500',
                            width: "105px",
                            marginTop: "20px",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                            onClick={() => console.log('next question')}
                        >
                            Next Question
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default Submission;
